import React from 'react'

const Location = () => {
    // var swiper = new Swiper(".mySwiper", {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    //     pagination: {
    //       el: ".swiper-pagination",
    //       clickable: true,
    //     },
    //     breakpoints: {
    //       640: {
    //         slidesPerView: 2,
    //         spaceBetween: 20,
    //       },
    //       768: {
    //         slidesPerView: 4,
    //         spaceBetween: 40,
    //       },
    //       1024: {
    //         slidesPerView: 5,
    //         spaceBetween: 50,
    //       },
    //     },
    //   });
    return (
        <>
       {/* <div className='mainloc' style={{position:'relative',zIndex:'2'}}>
       <div className="section-title pb-3 pt-5">
			<h2 className='' >Location</h2>
		
		</div>
        <div className="loca mx-auto">
            <div  className='locimg1 locimg'> <h5>America</h5><p>   2450 Peralta Blvd 216,
                            Fremont, CA - 94536,
                            USA</p></div>
            <div  className='locimg2 locimg'> <h5>India</h5>
            
            <p>Canvendor Software Solutions Private Limited
                            19/3 PRK Square, Court Road
                            Nagercoil, Tamilnadu - 629001,
                            India</p>
            </div>
            <div  className='locimg3 locimg'> <h5>UAE</h5>
                        <p>Canvendor Inc
                            2450 Peralta Blvd 216,
                            Fremont, CA - 94536,
                            UAE</p></div>
            <div  className='locimg4 locimg'> <h5>Canada</h5> <p>Canvendor Software Corp
                            1103-11871 Horseshoe Way
                            Vancouver
                            British Columbia V7A 5H5,
                            Canada</p></div>
            <div  className='locimg5 locimg'> <h5>UK</h5> <p>Canvendor Limited
                            2 Woodberry Grove
                            London
                            N12 0DR,
                            United Kingdom</p></div>
   
          
</div>
</div>  */}

<section id="services" class="common-section">
        <article class="container-fluid ">
        <div className='row'>
		<div className='col-12'>
			<div className='tp-section-wrapper text-center mb-70'>
				<span className='tp-section-subtitle mb-25'>OUR Locations</span>
				<h2 className='tp-section-title'>Our Global Presence</h2>
			</div>
		</div>
	</div>
    </article>   
    <div className='container-fluid'>
     <div class="row ">

                {/* <div class="col-md-4">
                    <h1 class="main-head  wow fadeInLeft animated pt-5" data-wow-delay=".5s" >OUR SERVICES</h1>
                
                    <div><span className="head-cube fl-left wow fadeInDown animated pt-5" data-wow-delay=".5s" ></span></div>
                    <p  style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>Web applications are growing in popularity because they can be updated  and maintained without having to distribute or install software on computers and because of their cross-platform compatibility.</p>
                    <p style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>
                        We can also develop from the simplest website to the most complex web-based applications and recommend the configuration that best meets your objectives. 
                    </p>
                </div> */}
                <div class="col-md-12 pb-5">

                <div class="serv_item wow locimg5 fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							{/* <AiOutlineCloudServer size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/> */}

                            <h3>USA</h3>
                            <p class="serv_cont_desc">7567 Amador Valley Blvd,<br></br>

                            Suite 106, Dublin,<br></br>

                            Ca - 94568 <br></br>

USA</p>
                         
                        </article>
                    </div>
                <div class="serv_item locimg4 wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							{/* <GiArtificialIntelligence className='mb-2 iconser' size={60} style={{color:'#001a57'}}/> */}
                            <h3>Canada</h3>
                            <p class="serv_cont_desc">Canvendor Software Corp <br></br>

1103-11871 Horseshoe Way <br></br>

Vancouver <br></br>

British Columbia V7A 5H5<br></br>

Canada</p>
                          
                        </article>
                    </div>
                    <div class="serv_item locimg1 wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						{/* <GiDiscussion size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/> */}
                            <h3>UK</h3>
                            <p class="serv_cont_desc">
                            Canvendor Limited <br></br>

2 Woodberry Grove <br></br>

London N12 0DR<br></br>

United Kingdom
                            </p>
                        
                        </article>
                    </div>
                    <div class="serv_item locimg3  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						{/* <BsBuilding size={60} className='mb-2 iconser' style={{color: "#001a57"}} /> */}
                            <h3>UAE</h3>
                            <p class="serv_cont_desc">CANVENDOR CORP LLC FZ.,<br></br> M Floor, The Meydan Hotel, <br></br> Al Sheba,<br></br> Dubai, U.A.E.</p>
      
                        </article>
                    </div>
                    <div class="serv_item locimg2 wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						{/* <CgWebsite size={60} className='mb-2 iconser' style={{color: "#001a57"}}/> */}
                            <h3>India</h3>
                            <p class="serv_cont_desc">
                            Canvendor Software Solutions pvt.
                            19/3 PRK Square,Court Road<br></br>
                            Nagercoil.
                            </p><hr></hr>
                            <p class="serv_cont_desc">
M63, Cactus Corporate, Suite #173,<br></br> 6th Floor, Block B,
Tecci Park,<br></br> OMR, Sholinganallur<br></br>
Chennai-600119</p>
                    
                        </article>
                    </div>
            
                  
                 
                
                    {/* <div class="serv_item wow locimg2 fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
				
                            <h3>Help &amp; Support</h3>
                            <p class="serv_cont_desc">IvoxPark’s claims management software helps guide adjusters through each claim to expedite.</p>
                            <a href="#">MORE</a>
                        </article>
                    </div> */}

                </div>

            </div>
            </div>
    </section>

 </>
    )
}

export default Location