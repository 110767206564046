import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NavComponent from '../../components/NavComponent'
import Applyingnew from './Applyingnew'
const Career = () => {
  const [buttonStyle,setButtonStyle] = useState({float:'right',maxWidth:'200px',backgroundColor:'#0E0E47',color:'#fff',marginBottom:'25px'})
	
	const handleHover =() =>{setButtonStyle({float:'right',maxWidth:'200px',border:'1px solid #47b2e4',color:'#47b2e4',marginBottom:'25px'})}
	const handleMoverLeave =() =>{setButtonStyle({float:'right',maxWidth:'200px',backgroundColor:'#47b2e4',color:'#fff',marginBottom:'25px !important'})}
  return (
    <>
     <NavComponent></NavComponent><br></br>
  <section id="carrer" className="carrer mt-5" style={{position:'relative',top:'50px',marginBottom:'60px'}}>
      <div class="container" data-aos="fade-up">

        <div class="row position-relative">

      
          <div class="col-lg-12">
            <h2>Careers.</h2>
            <div class="col-lg-7 carrer-img  overflow-hidden"></div>
            <div class="our-storycar">
              {/* <h4>Est 1988</h4> */}
              <h3>Explore your Role</h3>
			  <p style={{}}>At the core of the impact that we create as a team is the outstanding mix of different backgrounds, skills, and ways of creating value that we bring together.</p>
             

<br></br><br></br>
<button class="newreadmore" id='newreadmore1'><span><Link to='/contactUs'>Join us</Link></span></button>
				
            </div>
          </div>
         

        </div>

      </div>
     
    </section>
    {/* <Applyingnew></Applyingnew> */}

    </>
    
  )
}

export default Career