import React, { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { GiArrowed } from 'react-icons/gi'
import { Link } from 'react-router-dom'

const HomeAbout = () => {

	const [buttonStyle,setButtonStyle] = useState({float:'right',maxWidth:'200px',backgroundColor:'#0E0E47',color:'#fff',marginBottom:'25px'})
	
	const handleHover =() =>{setButtonStyle({float:'right',maxWidth:'200px',border:'1px solid #47b2e4',color:'#47b2e4',marginBottom:'25px'})}
	const handleMoverLeave =() =>{setButtonStyle({float:'right',maxWidth:'200px',backgroundColor:'#47b2e4',color:'#fff',marginBottom:'25px !important'})}

  return (
    <>


        <section className='pt-5 pb-5' style={{backgroundColor:'white'}}>

<div className='container pb-3 pt-5'>
    <div className='row'>
<div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>

OUR MISSION IS TO DELIVER SUCCESS</span>
        <h2 className='tp-section-title1 tp-section-title-large'>Canvendor co-builds a shared vision with our customers and focuses on accomplishing their business end goal. </h2>
    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch' >
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>

    </div>
</div>



        </section>


        <section class="about-section section-padding pb-3" style={{position:'relative'}}>
            <div class="container">
                <div class="row">
                    <div class="col col-md-6 colsty">
                        <div class="about-info">
                            <img src="about.png" alt/>
                            <div class="info">
                                <span>Know</span>
                                <h3>About Us</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col col-md-6">
                        <div class="about-text">
                            <h2>We harness the immense capabilities of the digital world </h2>
                            <p> 

Canvendor fuels businesses with modern engineering. We are one of the frontrunners in bringing about digital transformations for our clients from across the globe. As a company, we are adept at helping our clients with the modernization of existing tech, reconceive processes, and transform experiences so that they can stay ahead in their game in an ever-evolving world.</p>
                          <Link to='/about'><a href="" class="theme-btn">Read More</a> </Link>  
                        </div>
                    </div>
                </div>
            </div> 
        </section>



    </>
  )
}

export default HomeAbout