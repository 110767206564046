import React from 'react'
import NavComponent from '../../components/NavComponent'
import { Link } from 'react-router-dom'
import { CgData, CgDatabase, CgStack } from 'react-icons/cg'
import { DiAtlassian, DiCloud9 } from 'react-icons/di'
import { GiArtificialIntelligence, GiHypodermicTest } from 'react-icons/gi'

const Insurance = () => {
  return (
    
    <>
    <NavComponent></NavComponent>
    <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>INSURANCE</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
   
			  <div class="container mt-5 mb-3"><div class="row"><div class="col-lg-9"><div class="tp-section-wrapper"><span class="tp-section-subtitle tp-subtitle-before mb-3">Insurance</span><h2 class="tp-section-title tp-section-title-large">
        Transforming Insurance with Digital and Technology Implementation </h2><p>At Canvendor, we specialize in pioneering digital and technological solutions designed specifically for the insurance industry.</p></div></div></div><div class="row"><div class="col-lg-12"><p>Our cutting-edge services are tailored to enhance operational efficiency, reduce costs, and elevate the customer experience. By leveraging AI and intelligent processing, we empower insurance companies to navigate the complexities of the modern landscape with confidence and precision</p></div></div></div>
			  <div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'>CANVENDOR'S OFFERING</h1>
                      
                        </div>
        </div>

<div className='row'>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pl-25 pr-25 '>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
            Commercial & Specialty Insurance
			</h3>
			<p>
			Streamline operations from the front office to the back by integrating automation. This approach significantly reduces costs and boosts efficiency, while also enhancing outcomes and enabling the delivery of customized experiences to meet the specific needs of your clientele.
			</p>
			
		</div>
	</div>
		<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Personal Insurance Lines</a>
			</h3>
			<p>
            Leverage our advanced technological solutions to create uninterrupted omnichannel customer journeys. Digitize and refine the entire policy management process, from initial quotations to policy renewal, ensuring a seamless experience for policyholders.


			</p>
			
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Life Insurance Solutions</a>
			</h3>
			<p>
            Revitalize your life insurance technology ecosystem through digital transformation. This strategic overhaul will enhance your responsiveness, streamline complex processes, mitigate operational risks, and propel your organization towards sustained growth. 
			</p>
		
		</div>
	</div>
	
</div>
</div>


</div>
              <div className=''>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
 
        <h2 className='tp-section-title tp-section-title-large'> 

      <span style={{color:'#1F2148'}}> AI-Powered Document</span>  Forgery Detection </h2>
<p>In the digital age, the threat of document forgery is ever-present. Canvendor's AI-Powered Document Forgery Detection service is your first line of defense against this sophisticated fraud</p>
</div>
</div>
<div className='row'>
<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <CgDatabase  className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></CgDatabase>
			</div>
			<h3 className='tp-service__title text-left pb-25'>
          Data Collection
			</h3>
			<p>
            Amassing a vast dataset to ensure a robust foundation for accurate detection.
			</p>
			
		</div>
	</div>
    <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <CgStack  className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></CgStack>
			</div>
			<h3 className='tp-service__title text-left pb-25'>
           Analysis
			</h3>
			<p>
            Employing advanced analytical techniques to identify patterns and indicators of forgery.
			</p>
			
		</div>
	</div>
    <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <DiAtlassian className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></DiAtlassian>
			</div>
			<h3 className='tp-service__title text-left pb-25'>
       Training Algorithm
			</h3>
			<p>
            Crafting a bespoke AI algorithm that learns from the data to spot forgeries with unparalleled accuracy.
			</p>
			
		</div>
	</div>
    <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <GiArtificialIntelligence  className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></GiArtificialIntelligence >
			</div>
			<h3 className='tp-service__title text-left pb-25'>
      Generating AI Model
			</h3>
			<p>
            Developing a state-of-the-art AI model that integrates seamlessly with your existing systems.
			</p>
			
		</div>
	</div>
    <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <GiHypodermicTest  className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></GiHypodermicTest>
			</div>
			<h3 className='tp-service__title text-left pb-25'>
        Testing
			</h3>
			<p>
            Rigorously testing the model to ensure it meets the highest standards of reliability and efficiency.
			</p>
			
		</div>
	</div>
    <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
            <DiCloud9
             className='mb-2 iconser' style={{backgroundColor: "#1F2148",width:'50px',height:'30px',color:'white',padding:'5px'}}></DiCloud9>
			</div>
			<h3 className='tp-service__title text-left pb-25'>
           Deploying
			</h3>
			<p>
            Seamlessly integrating the AI model into your operations, transforming your document verification processes
			</p>
			
		</div>
	</div>
</div>
    
</div>

             
                </div>
              </div>




{/* 
                <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div> */}
              

    </>
  )
}

export default Insurance