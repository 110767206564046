import React from 'react'
import NavComponent from '../../components/NavComponent'

const BankingAndFinance = () => {
  return (
    <>
    <NavComponent></NavComponent>

    <div class="page-title-area item-bg-4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Banking</h2>
                            <p> Smart factories are the future of banking industries.</p>
                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-5"><div class="row"><div class="col-lg-9"><div class="tp-section-wrapper"><span class="tp-section-subtitle tp-subtitle-before mb-3">Banking</span><h2 class="tp-section-title tp-section-title-large">The world is amid a digital finance revolution</h2><p> Digital finance leverages technology to provide convenient, secure, and accessible financial services, transforming traditional banking methods and enhancing financial inclusion worldwide.</p></div></div></div><div class="row"><div class="col-lg-12"><p>A banking application, in today's digital age, stands as a testament to the seamless fusion of finance and technology. Designed to empower users with unparalleled convenience and control over their financial affairs, these applications have revolutionized the way we manage money. Through intuitive interfaces, they grant instant access to account balances, transaction histories, and a plethora of banking services. Security features such as biometric authentication, multi-factor authentication, and robust encryption algorithms ensure that users' sensitive data remains safeguarded.  </p><p class="text-dark"> Banking applications also facilitate effortless fund transfers, bill payments, and investment management, all from the comfort of one's device. With real-time alerts and notifications, users are kept informed about their financial activities, promoting financial literacy and responsible spending. In essence, banking applications have redefined banking, making financial management efficient, secure, and user-friendly for millions across the globe.</p></div></div></div>
                     
          
            <div class="ContentBuilder-Item pt-60"><div class="container"><div class="row"><div class="col-12"><h1 class="tp-section-title tp-section-title-large pb-60">OUR SERVICES</h1></div></div><div class="row"><div class="col-xl-4 col-md-6 tp"><div class="tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30"><div class="tp-service__icon"></div><h3 class="tp-service__title text-left pt-40 pb-25">Mobile Banking Application:</h3><p>A mobile banking application refers to a software application designed specifically for smartphones and tablets that allows users to access banking services and perform financial transactions remotely. Users can check account balances, transfer funds, pay bills, and conduct various banking activities using their mobile devices.</p></div></div><div class="col-xl-4 col-md-6 tp"><div class="tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30"><div class="tp-service__icon"></div><h3 class="tp-service__title  text-left pt-40 pb-25"><a>Online Banking Application: </a></h3><p>An online banking application, also known as internet banking or web banking, is a web-based platform provided by banks that allows customers to conduct financial transactions and access banking services over the internet. Users can log in to their accounts through a secure website and perform tasks.</p></div></div><div class="col-xl-4 col-md-6 tp"><div class="tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30"><div class="tp-service__icon"></div><h3 class="tp-service__title text-left  pt-40 pb-25"><a>Digital Wallet Application:</a></h3><p>A digital wallet application, often referred to as an e-wallet or mobile wallet, is a software-based application that stores users' payment card information, digital currency, or loyalty cards securely. It allows users to make electronic transactions, both online and in physical stores, using their mobile devices.</p></div></div></div></div></div>
    </>
  )
}

export default BankingAndFinance