import React from 'react'
import NavComponent from '../../components/NavComponent'
import '../services/service-css/style.css'
import { Link } from 'react-router-dom'
const DigitalService = () => {
  return (
    
    <>
    <NavComponent></NavComponent>

	<section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>DIGITAL TRANSFORMATION</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='SingleService'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 

		Digital transformation Services </h2>
<p> The pandemic has changed the way the world operates. The businesses stood the test of time only because of the strong IT infrastructure. IT leaders across the globe have been vocal about how IT has supported, workforces, customers, and industries with digital transformation.</p>
   
    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch'>
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>
               </div>
                </div>
              </div>


<div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'>CANVENDOR'S OFFERING</h1>
                      
                        </div>
        </div>

<div className='row'>
	<div className='col-xl-6 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
			UI/UX Design Ideation and Design 
			</h3>
			<p>
			Our entire range of UI/UX services constructs effective customer preservation strategies and assists brands in creating influential, human-centered personalities that engross and give shape to relationships with your patrons.
			</p>
			
		</div>
	</div>
		<div className='col-xl-6 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Data infrastructure and management </a>
			</h3>
			<p>
            Convert your data into treasured, actionable perceptions to make better business choices, generate new income streams, and win from the opposition.Canvendor has a dedicated development team that has the proficiency.


			</p>
			
		</div>
	</div>
	{/* <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Company Driven by AI</a>
			</h3>
			<p>
            Leaders that are at the top of their game are using AI to gain insight into what is happening and how it is happening. This enables them to take proactive actions to stay ahead. AI enables you to look at all available resources. 
			</p>
		
		</div>
	</div> */}
	
</div>
</div>
</div>
<div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

  Digital  SOLUTIONS  </h2>
  <p>The environment is now conducive to embracing intellectual, digitally enabled working models and strategies. 
Canvendor has a dedicated development team that has the proficiency, platforms, and aids to deliver flawless IT transformation services curated to stay ahead of ever-changing market needs.  </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div>
                {/* <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div> */}
{/* <section className='page-banner pt-xs-60 pt-sm-80 overflow-hidden'>
	<div className='container'>
		<div className='row align-items-center pt-5'>
			<div className='col-md-6 pt-5'>
				<div className='page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20'>
					<div className='transparent-text'>Services</div>
					<div className='page-title'><h1>Digital<span> Transformation</span>	</h1>
					</div>
				</div>
			</div>
			<div className='col-md-6 pt-5'>
				<div className='mt-xs-30 mt-sm-40'>
					<img src='../digt.jpg' width={400} className='img-fluid'></img>
				</div>
			</div>
		</div>
	</div>
</section>

<div className='company-skill pt-xs-80 pb-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden'>

	<div className='container'>
		<div className='row'>
			<div className='col-xl-6'>
				<div className='company-skill__content'>
					
					<h2 className='title color-pd_black mb-25 mb-xs-10 mb-sm-15'>Digital transformation Services</h2>
					<div className='description font-la'><p> The pandemic has changed the way the world operates. The businesses stood the test of time only because of the strong IT infrastructure. IT leaders across the globe have been vocal about how IT has supported, workforces, customers, and industries with digital transformation.</p>
                        <p>The environment is now conducive to embracing intellectual, digitally enabled working models and strategies. 
Canvendor has a dedicated development team that has the proficiency, platforms, and aids to deliver flawless IT transformation services curated to stay ahead of ever-changing market needs.</p>   </div>
<h4 className='title mb-25 mb-xs-10 mb-sm-15'>App modernization services </h4>
					<div className='description font-la'><p>Our specialists evaluate your corporation’s tech structure, procedures, and edifices to both reinforce the organization’s short-term performance and preserve sustainable long-term upgrading. App modernization is the process of updating legacy software for the latest computing methodologies, including the newest languages, structures, and groundwork platforms.  Partner with Canvendor for a seamless transformation.  </p>
                  </div>
				</div>
			</div>
			<div className='col-xl-6'>
				<div className='company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center'>


					<div className='company-skill__media'>
						<img src='http://www.canvendor.com/assets/images/services/app-modirazation1.png' className='img-fluid'></img>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<section className='why-choose pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden'>
    <div className='container'>
        <div className='row'>
            <div className='col-xl-6'>
                <div className='why-choose__media-wrapper d-flex flex-column'>
                    <div className='gallery-bar'></div>
                    <div className='why-choose__media'>
                        <img src='http://www.canvendor.com/assets/img/blog/blog-3.jpg' className='img-fluid'></img>
                    </div>
                    <div className='global-country text-center'></div>
                </div>
            </div>
            <div class="col-xl-6">
                    <div class="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                        <div class="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                       
                            <h2 class="title color-pd_black">CANVENDOR’S OFFERINGS </h2>
                            
               
                        </div>
                        <h4 className='title  mb-25 mb-xs-10 mb-sm-15'>UI/UX Design Ideation and Design </h4>
					<div className='description font-la'><p>Our entire range of UI/UX services constructs effective customer preservation strategies and assists brands in creating influential, human-centered personalities that engross, stimulate, and give shape to relationships with your patrons. 
</p>

    
</div>
<h4 className='title  mb-25 mb-xs-10 mb-sm-15'>Data infrastructure and management  </h4>
					<div className='description font-la'><p>Convert your data into treasured, actionable perceptions to make better business choices, generate new income streams, and win from the opposition. 
</p>

    
</div>
                    </div>
                </div>
        </div>
    </div>
</section> */}

      </>
  )
}

export default DigitalService