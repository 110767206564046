import React, { useEffect, useState } from 'react'
import HomeServices from '../components/home/HomeServices'
import NavComponent from '../components/NavComponent'
import { AiOutlineCloudServer } from 'react-icons/ai'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { BsBuilding } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { DiDigitalOcean } from 'react-icons/di'
import { Link } from 'react-router-dom'
import jsonData from '../blog.json';
const InsightPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
   
    setData(jsonData);
  }, []); 
  return (
    <>
    <NavComponent></NavComponent>    <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
		  </section>
		  <div className='container'>
							<div className='row'>
							  <div className='col-md-8 col-lg-6'>
								  <div className='heroPictureCardBox'>
									  <div className='heroPictureCardBoxTitle'>
										  <h1>Insight</h1>
									  </div>
								  </div>
							  </div>
							</div>
						</div>
            {data.map(item => (
          // <li key={item.id}>{item.name}</li>
    
<>



						<div className='SingleService' id='SingleService' key={item.blog}>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        
        <h2 className='tp-section-title tp-section-title-large' > 
	{item.heading}</h2>
<p> {item.content1}</p>
  <p>{item.content2}</p>  </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'>
<img src='../assets/images/blog/blog-1.jpg' style={{maxWidth:'100%',height:'auto'}}></img>
</div>
               </div>
                </div>
              </div>


			  {/* <div className='SingleService' id='SingleService1'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-4'>
			   <img src='../assets/img/blog/blog-2.jpg' style={{maxWidth:'100%',height:'auto'}} ></img>
</div>
<div className='col-lg-8 d-flex justify-content-lg-end'>
<div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 
		Clinical Shared Decision-Making </h2>
<p>   Empowering Patients, Engaging Clinicians: Uncover the collaborative world of Clinical Shared Decision-Making where patients and healthcare providers work hand-in-hand. Learn how this approach transforms the patient experience, promoting active involvement and shared responsibility.</p>
  <p>Navigating Complexity Through Insights: Dive into the complexities of healthcare decisions with our in-depth analysis of CSDM tools, frameworks, and success stories. Explore the ways in which data, evidence-based medicine, and patient preferences converge to drive informed choices.</p>  </div>

</div>
               </div>
                </div>
              </div> */}




              </>
    ))}
                <div className='container mt-5'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>

        <h2 className='tp-section-title tp-section-title-large'> 

		Global Compliance and Regulations </h2>
<p>  Navigate the complex world of compliance and regulatory frameworks governing eKYC on a global scale. Stay informed about the ever-evolving landscape of identity verification standards and best practices to ensure seamless and secure operations.  </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
<img src={'../assets/img/blog/blog-3.jpg'} style={{maxWidth:'100%',height:'auto'}}></img>
</div>
               </div>
                </div>

    </>
  )
}

export default InsightPage