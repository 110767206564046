import React from 'react'
import { BsArrowRight, BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Footer = () => {
    
    return (
        <>
       <div className='tp-theme-btn-area pt-90 pb-140' style={{backgroundColor:'white'}}>
        <div className='container'>
            
            <div className='row justify-content-center p-relative'>
                <div className='col-lg-12 d-flex justify-content-center'>
                <Link to='/contactUs'>  <a className='getintouch' ><div className='tp-theme-btn d-flex align-items-center white-bg'>
                        <div className='tp-btn-inner'> <p>Let’s Talk</p> <b>Get in Touch</b>
                        <span className='d-inline-block'><img src='../arrow.svg'></img></span></div></div></a></Link>
                </div>
            </div>
        </div>
       </div>

       

        <div class="overly">
            <div class="container"></div>
        </div>

            <footer id="footer">
                
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            
                            <div class="col-lg-3 col-md-6 footer-contact">
                                <img src={'../assets/images/canvendor_whitetransparent_logo (2).png'} width={'150px'} ></img>
                                <br></br><br></br>
                            
                                <p className='text-white ms-3'>
                                
                                    <strong><i class="bx bx-envelope"></i></strong> contact@canvendor.com<br />
                                </p>
                              
                                <h4 className='text-white ms-3 mt-5'>Our Social Networks</h4>
                                <p></p>
                                <div class="social-links mt-3 ms-3">
                                    <a href="https://twitter.com/Canvendor_Inc" class="twitter"><BsTwitter></BsTwitter></a>
                                    <a href="https://www.facebook.com/canvendor" class="facebook"><BsFacebook/></a>
                                    <a href="https://www.instagram.com/canvendor_inc/" target='_blank' class="instagram"><BsInstagram></BsInstagram></a>
                                    <a href="https://www.linkedin.com/company/canvendor/" target='_blank' class="google-plus"><BsLinkedin></BsLinkedin></a>
                                   
                                </div>
                            </div>
                            {/* footer Navlinks */}
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4 className='text-white'>Useful Links</h4>
                                <ul >
                                    <li ><i class="bx bx-chevron-right"></i>
                                 <a href="#" >Home</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <Link to='/about'>   <a href="">About us</a></Link>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <Link to="/services#service" >  <a href="">Services</a></Link>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                      <Link to="/Terms"> <a href="">Terms of service</a></Link> 
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                      <Link to="/privacyPolicy"> <a href="">Privacy policy</a></Link> 
                                    </li>
                                </ul>
                            </div>

                            {/* footer Services */}
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4 className='text-white'>Our Services</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <Link to='/services/aiml'>AI / ML Solutions</Link>
                                    </li><li>
                                        <i class="bx bx-chevron-right"></i>
                                        <Link to='/services/cloudSolutions'>Cloud Solutions</Link>
                                    </li>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <Link to='/services/digitalTransformation'>Digital Transformation Services</Link></li>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <Link to='/services/itConsultingService'>IT Consulting</Link>
                                    </li>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <Link to='/services/enterpriseService'>Enterprises Solutions</Link></li>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <Link to='/services/webAppService'>Web & App Development</Link></li>
                                </ul>
                            </div>

                            {/* footer social Media Links */}
                            <div class="col-lg-3 col-md-6 footer-links">
                            <h4 className='text-white'>Newsletter</h4>              
        <div class="footer-newsletter" style={{padding:'0px 0px !important'}}>
 
  
            <form action="" method="post" id="letTalk">
              <input type="email" name="email"/><input type="submit" value="Send"/>
            </form>

   








      
    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid footer-bottom clearfix text-center" id='cpy' style={{position:'relative',zIndex:'2',}}>
                    <div class="copyright text-center mx-auto"> © Copyright <strong><span>Canvendor</span></strong>.
                        All Rights Reserved</div>
                </div>

            </footer>


        </>
    )
}

export default Footer