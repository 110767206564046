import React from 'react'
import { Link } from 'react-router-dom'

const Insights = () => {
  return (
    <>
    
    <div className='tp-feature-area position-relative'>
    <div className='tp-fe-bg d-none d-lg-block d-sm-block d-xs-block'></div>
    <div className='container-fluid p-0'>
        <div className='row g-0 justify-content-end'>
            <div className='col-xl-4 col-lg-12 col-sm-12 tp-modify-width d-block'>
                <div className='tp-section-title-sm-box tp-white-text grey-bg-3 hide-content'>
          
                    <h3 className='tp-section-title-sm tp-white-text-sm mt-3'>Why Canvendor</h3>
                    <p> Canvendor is focused on bringing in the latest levels of relevance to large, established organizations through the well-researched application of the newest technologies.</p>
                 <p>•	Canvendor is established in 2016. Having 7+ years of industry experience.</p>
                 <p>•	Operating in 6 global locations with near shore centres in US, Mexico, Canada, UK and UAE</p>
                 <p>•	80% of our revenue comes from recurring orders ensuring customer satisfaction.</p>
            
                 <p>•	Specialized in setting up state-of-art Offshore Development Centre</p>
      
                </div>
                <div className='tp-section-title-sm-box tp-white-text grey-bg-3 hide-content'>
                
                    {/* <h3 className='tp-section-title-sm tp-white-text-sm mb-5'>Unique & modern business tips for our clients</h3> */}
                    {/* <p>Tellus rutrum tellus pellentesque eu. Sagittis purus sit amet volutpat.</p> */}
                </div>
            </div>
        </div>
    </div>
</div>

<div className='tp-blog-area pt-130 bg-white'>
  <div className='container'>
    <div className='row'>
      <div className='col-12'>
        <div className='tp-section-wrapper text-center pb-60'>
          <span className='tp-section-subtitle mb-25'>
          Our Latest News & Blog
          </span>
          <h2 className='tp-section-title'>
          Stay updated with our <br/>Insights
          </h2>
        </div>
      </div>
    </div>
    <div className='row'>
      <div className='col-xl-6'>
        <div className='tp-blog'>
          <div className='tp-blog__thumb'>
            <img src={'../assets/img/blog/blog-3.jpg'} style={{maxWidth:'100%',height:'auto'}}></img>
          </div>
        
          <h3 className='tp-blog__title tp-large-title'>
            <a> Navigating the Digital Healthcare Revolution</a>
          </h3>
        </div>
      </div>


      <div className='col-xl-6'>
        <div className='tp-blog-list'>
          <div className='tp-blog-list__item d-flex pl-30 pb-20'>
            <div className='tp-blog-list__item-thum w-img'>
              <img src='../assets/images/blog/blog-1.jpg' style={{maxWidth:'100%',height:'auto'}} width={260}></img>
            </div>
            <div className='tp-blog-list__item-details  pb-30'>
              <div className='list-blog-meta'>
              
              <h3 className='list-blog-title pt-15 pb-10 d-inline-block'><a> Understand the critical aspects of data security </a></h3>
              <Link to='/insights#SingleService'> <a className='tp-btn-sm-black'>
                <img src='../arrowwithcircle.svg'></img><span className='' style={{marginLeft:'15px'}}>View More</span>
              </a></Link>
              </div>
            </div>
          </div>





          <div className='tp-blog-list__item d-flex pl-30 pb-20'>
            <div className='tp-blog-list__item-thum w-img'>
              <img src='../assets/img/blog/blog-2.jpg' style={{maxWidth:'100%',height:'auto'}} width={257}></img>
            </div>
            <div className='tp-blog-list__item-details pb-30'>
              <div className='list-blog-meta'>
         
              <h3 className='list-blog-title pt-15 pb-10 d-inline-block'><a>Explore technologies that complement EMRs</a></h3>
              <Link to='/insights#SingleService1'>    <a className='tp-btn-sm-black'>
                <img src='../arrowwithcircle.svg'></img><span className='' style={{marginLeft:'15px'}}>View More</span>
              </a></Link>
             
              </div>
            </div>
          </div>


          <div className='tp-blog-list__item d-flex pl-30 pb-20'>
            <div className='tp-blog-list__item-thum w-img'>
              <img src='../assets/img/blog/blog-2.jpg' style={{maxWidth:'100%',height:'auto'}} width={305}></img>
            </div>
            <div className='tp-blog-list__item-details  pb-30'>
              <div className='list-blog-meta'>
           
              <h3 className='list-blog-title pt-15 pb-10 d-inline-block'><a>Hear from industry experts, healthcare professionals</a></h3>
              <Link to='/insights'>  <a className='tp-btn-sm-black'>
                <img src='../arrowwithcircle.svg'></img><span className='' style={{marginLeft:'15px'}}>View More</span>
              </a>
            </Link>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>







































	 {/* <section id="recent-blog-posts" className="recent-blog-posts pt-5 pb-5" style={{position:'relative',zIndex:'2'}}>
      <div class="container" data-aos="fade-up">

    
    
  <div class="section-header pb-3" id='blognew'>
        <h2 className='text-white pb-3'>INSIGHTS</h2>
    
      </div>

      <div class="row gy-5 gx-3">

        <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="post-item position-relative h-100">

            <div class="post-img position-relative overflow-hidden">
              <img src={'http://www.canvendor.com/assets/images/blog/blog-1.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">December 12</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Eum ad dolor et. Autem aut fugiat debitis</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Julia Parker</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Politics</span>
                </div>
              </div>

              <hr/>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2" ><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

        <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div class="post-item position-relative h-100">

            <div class="post-img position-relative overflow-hidden">
              <img src={'http://www.canvendor.com/assets/img/blog/blog-2.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">July 17</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Et repellendus molestiae qui est sed omnis</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Mario Douglas</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Sports</span>
                </div>
              </div>

              <hr></hr>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

        <div class="col-xl-4 col-md-6">
          <div class="post-item position-relative h-100" data-aos="fade-up" data-aos-delay="300">

            <div class="post-img position-relative overflow-hidden">
              <img  src={'http://www.canvendor.com/assets/img/blog/blog-3.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">September 05</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Quia assumenda est et veritati tirana ploder</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Lisa Hunter</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Economics</span>
                </div>
              </div>

              <hr/>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

      </div>

      </div>
    </section>



 */}































</>
  )
}

export default Insights