import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import NavComponent from "../components/NavComponent";
import { BsEnvelopeFill, BsFillMapFill, BsMap, BsPinMap } from "react-icons/bs";
import { GiMapleLeaf } from "react-icons/gi";
import {
  FaMap,
  FaMapMarker,
  FaMapMarkerAlt,
  FaPhoneVolume,
  IconName,
} from "react-icons/fa";
import Swal from "sweetalert2";
const Contact = () => {
  const [formData, setFormData] = useState({
    uname: "",
    email: "",

    subject: "",
    message: "",
    // Add more input fields if needed
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    Swal.fire({
      title: "successfully submited",
      text: "",
      icon: "success",
      confirmButtonText: "ok",
    });
    e.preventDefault();
    console.log(formData);
  };

  return (
    <>
      <NavComponent></NavComponent>
      <section className="page-bannernew pt-xs-60 pt-sm-80 overflow-hidden"></section>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <div className="heroPictureCardBox">
              <div className="heroPictureCardBoxTitle">
                <h1>CONTACT US</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact-us__item mb-40">
                <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                  <div className="icon me-2 color-primary">
                    {" "}
                    <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                  </div>
                  <h5 className="title color-d_black contactloc">India</h5>
                </div>
                <div className="contact-us__item-body font-la ms-3 ps">
                  <h5 className="address-type">Nagercoil</h5>
                  Canvendor Software Solutions pvt. 19/3 PRK Square, Court Road
                  <br></br>
                  Nagercoil - 629001, Tamilnadu.
                </div>
                <hr></hr>
                <div className="contact-us__item-body font-la ms-3 mt-3">
                  <h5 className="address-type">Chennai</h5>
                  M63,Cactus Corporate, Suite#173,<br></br> 7th Floor, Block B,
                  Tecci Park,<br></br> OMR, Sholinganallur<br></br>
                  Chennai - 600119, Tamilnadu.
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc"> USA</h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      7567 Amador Valley Blvd,<br></br>
                      Suite 106, Dublin,<br></br>
                      CA - 94568.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        {" "}
                        Canada
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      Canvendor Software Corp <br></br>
                      1103-11871 Horseshoe Way <br></br>
                      Vancouver <br></br>
                      British Columbia V7A 5H5.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        United Kingdom
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3 ps">
                      {" "}
                      Canvendor Limited <br></br>2 Woodberry Grove <br></br>
                      London N12 0DR.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc"> UAE</h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      CANVENDOR CORP LLC FZ.,<br></br> M Floor, The Meydan
                      Hotel, <br></br> Al Sheba,<br></br> Dubai.
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        Singapore
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      CANVENDOR PTE.LTD.
                      <br />9 Raffles Place
                      <br />
                      #29-05 Republic Plaza,
                      <br /> Singapore(048619).
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-form  mb-xs-80 mb-sm-100 mb-md-100 mb-120 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-ct-form white-bg pl-110 pr-110 pt-80 pb-60">
                <h2 className="mb-4">GET IN TOUCH</h2>
                <form
                  id="contact-form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    required
                    name="uname"
                    value={formData.uname}
                    onChange={handleInputChange}
                    maxLength={20}
                  />
                  <input
                    type="email"
                    name="email"
                    id="name"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />

                  <input
                    type="text"
                    name="subject"
                    id="name"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    maxLength={50}
                  />
                  <textarea
                    placeholder="Enter your Message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>

                  <section className="disclaimer-footer mb-4">
                    <div className="disclaimer-privacy">
                      <div className="disclaimer text-start">
                        <h2
                          className="text-uppercase mb-3"
                          style={{ fontWeight: 700, fontSize: 10 }}
                        >
                          Disclaimer
                        </h2>
                        <p style={{ fontSize: 9 }}>
                          "By providing my phone number to “Canvendor”, I agree
                          and acknowledge that “Canvendor” may send text
                          messages to my wireless phone number for any purpose.
                          Message and data rates may apply. Message frequency
                          will vary, and you will be able to Opt-out by replying
                          “STOP”. For more information on how your data will be
                          handled please See the{" "}
                          <a href="https://www.canvendor.com/privacyPolicy">
                            Privacy Policy.
                          </a>
                        </p>
                      </div>
                    </div>
                  </section>
                  <div className="text-center">
                    <button className="tp-btn-border" type="submit">
                      Send Messages
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
