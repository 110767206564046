import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation } from 'react-router-dom'
import './css/banner.css'
const NavComponent = () => {  

  const [navBackground, setNavBackground] = useState(false)
    const navRef = useRef()
    navRef.current = navBackground
    useEffect(() => {
      const handleScroll = () => {
        const show = window.scrollY > 50
        if (navRef.current !== show) {
          setNavBackground(show)
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])


  const [scrollPosition, setScrollPosition] = useState(0);
  const [pathname,setPathname] = useState('/')
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setScrollPosition(currentScrollPosition);
      setPathname(location.pathname)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, [location.pathname]);

  let navStyle,linkStyle
  if(pathname != '/'){
    navStyle = {
      // backgroundColor: '#fff',
    //  height:'20px',
      transition: 'background-color 0.5s ease-in-out',
    };
  
    linkStyle = {
      color:  '#0E0E47' ,
      
      transition: 'color 0.5s ease-in-out',
    };
  }else{
    navStyle = {
      backgroundColor: scrollPosition > 75 ? '#fff': 'transparent' ,
      transition: 'background-color 0.5s ease-in-out',
    };
  
    linkStyle = {
      color: scrollPosition > 75 ? '#0E0E47' : '#fff',
      transition: 'color 0.5s ease-in-out',
    };
  }
  

  // return (
  //   <>
  //     <header id="header" className="fixed-top header-inner-pages header-scrolled header-test" style={navStyle}>
  //       <div className="container d-flex align-items-center">
  //         <h1 className="logo me-auto " style={{ backgroundColor: '#fff!important' }}>
  //           <img src={require('../images/canvendor_logo.jpg')} />
  //         </h1>

          

  return (
    <>
    
    <Navbar expand="lg"  fixed="top" 
    /*  style={{zIndex:'2',top:'0px',backgroundColor:'rgb(31,33,72)'}}*/
    style={{transition: '1s ease',backgroundColor: navBackground ? '#1F2148' : 'transparent'}}
    >
      <Container>
        <Navbar.Brand href="#home"><img className='img-fluid' src={'../assets/images/canvendor_whitetransparent_logo (2).png'} width={150} height={30}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"  style={{border:'2px solid white',backgroundColor:'rgba(255, 255, 255, 0.527)'}}/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ms-auto">
         <Nav.Link ><Link to='/'>Home</Link>  </Nav.Link>
            <Nav.Link ><Link to='/about'>About</Link></Nav.Link>
           
         
          <li className="dropdown" ><Link to="/services" > Services<i className="bi bi-chevron-down"></i></Link>
						<ul>
                <li><Link to="/services/aiml">AI/ML SOLUTIONS</Link></li>
                <li><Link to="/services/cloudSolutions">CLOUD SOLUTIONS</Link></li>
                <li><Link to="/services/digitalTransformation">DIGITAL TRANSFORMATION SERVICES</Link></li>
                <li><Link to="/services/itConsultingService">IT CONSULTING</Link></li>
                <li><Link to="/services/enterpriseService">ENTERPRISES SOLUTIONS</Link></li>
                <li><Link to="/services/webAppService">WEB & APP DEVELOPMENT</Link></li>
               
						</ul>
          </li>
          <li className='dropdown' ><Link to="" className="nav-link scrollto" >Industries</Link>
            <ul>
                <li><Link to='/industries/manufacturing'>MANUFACTURING</Link></li>
                <li><Link to='/industries/automotive'>AUTOMOTIVE</Link></li>
                <li><Link to='/industries/banking&finance'>BANKING & FINANCE</Link></li>
                <li><Link to='/industries/communications&information'>COMMUNICATIONS & INFORMATION</Link></li>
                <li><Link to='/industries/healthcare'>HEALTHCARE</Link></li>
                <li><Link to="/industries/insurance">INSURANCE</Link></li>
            </ul>
           </li>
            <Nav.Link> <Link to='/carrer'>Careers</Link></Nav.Link>
            <Nav.Link> <Link to='/contactUs'>Contact</Link></Nav.Link>
            {/* <Nav.Link><Link to='/enquiry'>Enquriy</Link></Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

export default NavComponent