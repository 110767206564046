import React from 'react'
import HomeServices from '../components/home/HomeServices'
import NavComponent from '../components/NavComponent'
import { AiOutlineCloudServer } from 'react-icons/ai'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { BsBuilding } from 'react-icons/bs'
import { CgDatabase, CgWebsite } from 'react-icons/cg'
import { DiAtlassian, DiDigitalOcean } from 'react-icons/di'
import { Link } from 'react-router-dom'

const Services = () => {
  return (
    <>
    <NavComponent></NavComponent>    <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
		  </section>
		  <div className='container' id="service">
							<div className='row'>
							  <div className='col-md-8 col-lg-6'>
								  <div className='heroPictureCardBox'>
									  <div className='heroPictureCardBoxTitle'>
										  <h1>SERVICES</h1>
									  </div>
								  </div>
							  </div>
							</div>
						</div>





<div className='our-company pt-140 pb-140'>

<div className='container'>
	<div className='row'>
		<div className='col-12'>
			<div className='tp-section-wrapper text-center mb-70'>
				<span className='tp-section-subtitle mb-25'>OUR SERVICES</span>
				<h2 className='tp-section-title'>Explore Our Services</h2>
			</div>
		</div>
	</div>

<div className='row'>
	<div className='col-xl-4 col-md-6 tp'>
	
<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>It Consulting</a>
			</h3>
			<p>
			Insurance ERP is a boon to organizations which help in integrating all company data from client  invoicing.	
			</p>
			<Link to="/services/itConsultingService">	<a className='tp-btn-sm-black '><span>View MOre <img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>

	</div>
		<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<GiArtificialIntelligence  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>AI/ML Solutions</a>
			</h3>
			<p>
			At Canvendor we understand the pain of collecting, processing, and analyzing data that is incredibly valuable, yet burdensome to handle.
			</p>
			<Link to="/services/aiml"><a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
	
<div className='tp-service pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<AiOutlineCloudServer className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Cloud Solutions</a>
			</h3>
			<p>
			Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner.
			</p>
			<Link to="/services/cloudSolutions">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>

	</div>
	
</div>

<div className='row'>
<div className='col-xl-4 col-md-6 tp'>
<div className='tp-service pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a> Application Solutions</a>
			</h3>
			<p>
			Provides insurance ERP is a boon to organizations which help in integrating all company data from client.	
			</p>
			<Link to="/services/webAppService">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
	
	<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<DiDigitalOcean className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Digital Transformation</a>
			</h3>
			<p>
			The pandemic has changed the way the world operates. The businesses stood the test of time only because of the strong IT infrastructure.
			</p>
			<Link to="/services/digitalTransformation"><a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div>

	</div>
		<div className='col-xl-4 col-md-6 tp'>
		
		<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgDatabase className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Enterprise Solutions</a>
			</h3>
			<p>
			Enterprise solutions streamline operations.CX is a vital factor when one is looking to stay ahead in the digital age.
			</p>
			<Link to="/services/enterpriseService">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div>
	</div>
{/* 
	<div className='col-xl-4 col-md-6 tp'>
		
		<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<DiAtlassian className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Insurance</a>
			</h3>
			<p>
			Our cutting-edge services are tailored to enhance operational efficiency, reduce costs, and elevate the customer experience.
			</p>
			<Link to="/services/insurance">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div>
	</div> */}
</div>
</div>

</div>
    </>
  )
}

export default Services