import React from 'react'
import { AiOutlineCloudServer } from "react-icons/ai";
import { DiAtlassian, DiDigitalOcean } from "react-icons/di";
import { BsBuilding } from "react-icons/bs";
import { GiArtificialIntelligence, GiDiscussion } from "react-icons/gi";
import { CgDatabase, CgWebsite } from "react-icons/cg";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';

const HomeServices = () => {
	
	const responsive = {
		superLargeDesktop: {
		
		  breakpoint: { max: 4000, min: 3000 },
		  items: 3
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 3
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 3
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };

	  const CustomLeftArrow = ({ onClick, ...rest }) => {
		const {
		  onMove,
		  carouselState: { currentSlide, deviceType }
		} = rest;
		// onMove means if dragging or swiping in progress.
		return <button class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left disable-arrow" 
		type="button" onClick={() => onClick()}> <img src='../arrowleft.svg' ></img></button>;
	  };
	  const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
		  onMove,
		  carouselState: { currentSlide, deviceType }
		} = rest;
		// onMove means if dragging or swiping in progress.
		return <button class="react-multiple-carousel__arrow react-multiple-carousel__arrow--right disable-arrow" style={{ '::before': { content: 'unset' } }}
		type="button" onClick={() => onClick()}> <img src='../arrowright.svg' ></img></button>;
	  };
  return (
    <>



<div className='our-company grey-bg-4 pt-5 pb-60' style={{position:'relative',zIndex:'2'}}>

<div className='container'>

	<div className='row'>
		<div className='col-12'>
			<div className='tp-section-wrapper text-center mb-70'>
				<span className='tp-section-subtitle mb-25'>OUR SERVICES</span>
				<h2 className='tp-section-title'>Explore Our Services</h2>
			</div>
		</div>
	</div>


<Carousel responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow/>} data-aos="fade-up">



<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>IT Consulting</a>
			</h3>
			<p>
			Canvendor is home to the best IT minds in the industry and their in-depth tech knowledge to create an all-encompassing strategy.
			</p>
			<Link to="/services/itConsultingService">	<a className='tp-btn-sm-black '><span>View MOre <img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>




<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<GiArtificialIntelligence  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>AI/ML Solutions</a>
			</h3>
			<p>
			At Canvendor we understand the pain of collecting, processing, and analyzing data that is incredibly valuable, yet burdensome to handle.
			</p>
			<Link to="/services/aiml"><a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>

<div className='tp-service pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<AiOutlineCloudServer className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Cloud Solutions</a>
			</h3>
			<p>
			Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner.
			</p>
			<Link to="/services/cloudSolutions">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>


		<div className='tp-service pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a> Application Solutions</a>
			</h3>
			<p>
			Provides insurance ERP is a boon to organizations which help in integrating all company data from client.	
			</p>
			<Link to="/services/webAppService">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg' width={40}></img></span></a></Link>
		</div>


		<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<DiDigitalOcean className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Digital Transformation</a>
			</h3>
			<p>
			The pandemic has changed the way the world operates. The businesses stood the test of time only because of the strong IT infrastructure.
			</p>
			<Link to="/services/digitalTransformation"><a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div>

	
		
		<div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgDatabase className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Enterprise Solutions</a>
			</h3>
			<p>
			Enterprise solutions streamline operations.CX is a vital factor when one is looking to stay ahead in the digital age.
			</p>
			<Link to="/services/enterpriseService">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div>
		{/* <div className='tp-service  pt-25 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<DiAtlassian className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Insurance</a>
			</h3>
			<p>
			Our cutting-edge services are tailored to enhance operational efficiency, reduce costs, and elevate the customer experience.
			</p>
			<Link to="/services/insurance">	<a className='tp-btn-sm-black'><span>View MOre<img src='../arrowwithcircle.svg'  width={40}></img></span></a></Link>
		</div> */}
</Carousel>


<div className='text-center mt-3 pt-3  allser'>
<Link to="/services" >	<a className='theme-btn1' href='/services'>All Services</a></Link>
		
	</div>
	</div>
{/* <div className='row'>
	<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>It Consulting</a>
			</h3>
			<p>
			Insurance ERP is a boon to organizations which help in integrating all company data from client  invoicing.	
			</p>
			<a className='tp-btn-sm-black '><span>View MOre</span></a>
		</div>
	</div>
		<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<BsBuilding  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Enterprises Solutions</a>
			</h3>
			<p>
			The perfect solutions to manage your sales process and optimize pipeline generation.	
			</p>
			<a className='tp-btn-sm-black '><span>View MOre</span></a>
		</div>
	</div>
	<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<GiArtificialIntelligence  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>AI/ML SOLUTIONS</a>
			</h3>
			<p>
			Discover your applications with easily map comprehensive insight into your complex IT.	
			</p>
			<a className='tp-btn-sm-black'><span>View MOre</span></a>
		</div>
	</div>
		<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<AiOutlineCloudServer className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>CLOUD SOLUTIONS</a>
			</h3>
			<p>
			A web application is a software application that the user interacts with via a browser.
			</p>
			<a className='tp-btn-sm-black'><span>View MOre</span></a>
		</div>
	</div>
	<div className='text-center mt-60 allser'>
		<a className='tp-btn-border' href='/services'>All Services</a>
		
	</div>
</div>

</div> */}

</div>









































{/* <section id="services" class="common-section grey-section">
        <article class="container-fluid mt-5 mb-5">
            <div class="row ">

                <div class="col-md-4">
                    <h1 class="main-head  wow fadeInLeft animated pt-5" data-wow-delay=".5s" >OUR SERVICES</h1>
                
                    <div><span className="head-cube fl-left wow fadeInDown animated pt-5" data-wow-delay=".5s" ></span></div>
                    <p  style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>Web applications are growing in popularity because they can be updated  and maintained without having to distribute or install software on computers and because of their cross-platform compatibility.</p>
                    <p style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>
                        We can also develop from the simplest website to the most complex web-based applications and recommend the configuration that best meets your objectives. 
                    </p>
                </div>
                <div class="col-md-8 ">



                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<CgWebsite size={60} className='mb-2 iconser' style={{color: "#001a57"}}/>
                            <h3>IT CONSULTING</h3>
                            <p class="serv_cont_desc">
                                Insurance ERP is a boon to organizations which help in integrating all company data from client enquiry to invoicing.
                            </p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<BsBuilding size={60} className='mb-2 iconser' style={{color: "#001a57"}} />
                            <h3>Enterprises Solutions</h3>
                            <p class="serv_cont_desc">The perfect solutions to manage your sales process and optimize pipeline generation.</p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							<GiArtificialIntelligence className='mb-2 iconser' size={60} style={{color:'#001a57'}}/>
                            <h3>Web/ Mobile App Development Solutions</h3>
                            <p class="serv_cont_desc">Discover your applications with easily map their relationships to gain a comprehensive insight into your complex IT infrastructure.</p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							<AiOutlineCloudServer size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/>

                            <h3>AI/ML SOLUTIONS</h3>
                            <p class="serv_cont_desc">earning professionals must demonstrate strong consulting skills to successfully guide their organizations toward optimal performance.</p>
                            <a href="#">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<GiDiscussion size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/>
                            <h3>CLOUD SOLUTIONS</h3>
                            <p class="serv_cont_desc">
                                A web application is a software application that the user interacts with via a browser.We specialise in taking and making your business processes online. 
                            </p>
                            <a href="#">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<DiDigitalOcean size={60} className='mb-2 iconser'  style={{color:'#001a57'}}/>
                            <h3>Help &amp; Support</h3>
                            <p class="serv_cont_desc">IvoxPark’s claims management software helps guide adjusters through each claim to expedite.</p>
                            <a href="#">MORE</a>
                        </article>
                    </div>

                </div>

            </div>
        </article>
    </section> */}





</>
  )
}

export default HomeServices
