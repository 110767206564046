import React from 'react'
import { Accordion, Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Banner from '../components/home/Banner';
import HomeAbout from '../components/home/HomeAbout';
import WhyCanvendor from '../components/home/WhyCanvendor';
import HomeIndustries from '../components/home/HomeIndustries';
import HomeServices from '../components/home/HomeServices';

import Location from '../components/home/Location';
import Insights from '../components/home/Insights';
import NavComponent from '../components/NavComponent';


const Home = () => {
  return (
	
    <div className='home-body' id="home-body">
		
	{/* <HomeVideo/> */}
	{/* carousel section or slider */}
      <Banner/>
{/* Clients Logos */}

<HomeAbout/>
<HomeServices/>
{/* <WhyCanvendor/> */}
{/* <HomeIndustries/> */}

{/* industries */}



{/* services */}




<Insights/>
<Location/>
{/* <section id="clients" className="clients pt-5 bg-white" style={{position:'relative',zIndex:'2'}}>


	<div className="container"><div className="row  aos-animate slider" >
		<div className='slider-wrapper'>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo.png"} style={{width:'200px',height:'50px'}} className="img-fluid home_img_logo" alt="LOGO1"/>
		</div>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo_1.png"} className="img-fluid" alt="LOGO2"/>
		</div>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo_2.png"} className="img-fluid" alt="LOGO3"/>
		</div>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo_3.avif"} className="img-fluid" alt="LOGO4"/>
		</div>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo_4.jpg"} className="img-fluid" alt="LOGO5"/>
		</div>
		<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
			<img src={"http://www.canvendor.com/assets/images/home/client_logo/home_logo_5.png"} className="img-fluid" alt="LOGO6"/>
		</div>
		</div>
	</div>
	</div>
</section> */}
    </div>
  )
}

export default Home