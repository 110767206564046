import React from 'react'
import NavComponent from '../../components/NavComponent'
import '../services/service-css/style.css'
import { Link } from 'react-router-dom'
const Enterprise = () => {
  return (
    
    <>
    <NavComponent></NavComponent>
	<section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>ENTERPRISE SERVICES</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='SingleService'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 

		Enterprise Services </h2>
		<p> A strong customer experience or CX is a vital factor when one is looking to stay ahead in the digital age. You have to bear in mind that the CX has to be in tandem with the organization’s value chain. There has to be a clear bifurcation in the way one engages with clients, employees, and partners. 

</p>

{/* <p>Canvendor simplifies and modernizes the existing landscape and helps our customers stay ahead in the digital economy.The enterprise application of any organization is at the core of its IT structure. Canvendor helps digitally transform multiple processes in an organization including CX, CRM, HCM, SCMM, and ERP </p> */}
    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch'>
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>
               </div>
                </div>
              </div>


<div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'>CANVENDOR'S OFFERING</h1>
                      
                        </div>
        </div>

<div className='row'>
	<div className='col-xl-6 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
			Crafting a persuasive CX
			</h3>
			<p>
			Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner in driving and delivering results. Cloud infrastructure modernization and maintenance is a combination of the latest technologically advanced methods, subject matter experts, and leading engineers to empower industry-focused results and user engagement.
			</p>
			
		</div>
	</div>
		<div className='col-xl-6 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Mapping milestones </a>
			</h3>
			<p>
			Identifying the key moments in transforming the customer service journey by helping them outline their goals with measurable outcomes like costs, satisfaction scores, and a higher customer value. It is possible to create hands-on business value and customer service by organizing the perfect software, platforms, and data.Accompanied by Canvendor’s digital HR offerings to provide the companies

			</p>
			
		</div>
	</div>
	{/* <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Company Driven by AI</a>
			</h3>
			<p>
            Leaders that are at the top of their game are using AI to gain insight into what is happening and how it is happening. This enables them to take proactive actions to stay ahead. AI enables you to look at all available resources. 
			</p>
		
		</div>
	</div> */}
	
</div>
</div>
</div>
<div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

		The right toolkit for HR digital transformation 

   </h2>
<p> With processes undergoing a sea change, it is obvious that the other arms of an organization will have to change along with them in tandem. The HR practices are not as they were before, the workforce is evolving, mergers and acquisitions are rife, and the regulations are changing and evolving.   </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div>
    </>
  )
}

export default Enterprise