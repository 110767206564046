import React from 'react'
import HomeServices from '../components/home/HomeServices'
import NavComponent from '../components/NavComponent'
import { AiOutlineCloudServer } from 'react-icons/ai'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { BsBuilding } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { DiDigitalOcean } from 'react-icons/di'
import { Link } from 'react-router-dom'

const Termsofservice = () => {
  return (
    <>
    <NavComponent></NavComponent>    <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
		  </section>
		  <div className='container'>
							<div className='row'>
							  <div className='col-md-8 col-lg-6'>
								  <div className='heroPictureCardBox'>
									  <div className='heroPictureCardBoxTitle'>
										  <h1>Terms of Service</h1>
									  </div>
								  </div>
							  </div>
							</div>
						</div>

<div className='termsofservice mt-5'>
    <div className='container'>
        <div className='row'>
            <div className='col-12 col-md-11 col-lg-10 offset-md-1 offset-lg-1'>
                <div className='article-text article-quote-text'>
                    <h2>Acceptance of Terms</h2>
                    <p>By using the IT consulting services provided by Canvendor Software Solutions, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our services.</p>
                    <h2>Scope of Services</h2>
                    <p>Canvendor Software Solutions offers IT consulting services, including but not limited to technology assessment, strategy development, project management, and technical support.</p>
                    <h2>Service Fees</h2>
                    <p>The fees for our IT consulting services will be agreed upon in advance and outlined in a separate agreement or statement of work. Payment terms and methods will also be specified in that agreement.</p>
                    <h2>Intellectual Property</h2>
                    <p>Any intellectual property, including but not limited to documents, reports, and software, created during the provision of IT consulting services shall be the property of Canvendor Software Solutions or its respective owners as specified in the agreement.</p>
                    <h2>Confidentiality</h2>
                    <p>We respect the confidentiality of your information and maintain strict confidentiality of all data and materials shared with us during the consulting engagement. Any exceptions will be defined in the consulting agreement.</p>
                    <h2>Data Security</h2>
                    <p>We implement industry-standard security measures to protect your data. However, Canvendor Software Solutions is not responsible for any data breaches or loss of data on your end.</p>
                    <h2>Limitation of Liability</h2>
                    <p>Canvendor Software Solutions will not be liable for any indirect, special, consequential, or incidental damages, including, but not limited to, loss of data, profits, or business interruptions.</p>
                    <h2>Termination</h2>
                    <p>We reserve the right to terminate or suspend our services at our discretion, with or without notice, if we believe you have violated these Terms of Service.</p>
                    <h2>Governing Law</h2>
                    <p>These Terms of Service are governed by and interpreted under the laws of Nagercoil Jurisdiction, and any disputes shall be subject to the exclusive jurisdiction of the courts in Nagercoil Jurisdiction.</p>
                    <h2>Changes to Terms</h2>
                    <p>We may update these Terms of Service at our discretion. The most current version will be posted on our website with the effective date.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Terms of Service or our services in general, please contact us at contact@canvendor.com</p>
                </div>
            </div>
        </div>
    </div>
</div>



    </>
  )
}

export default Termsofservice