import React from 'react'
import NavComponent from '../../components/NavComponent'
import '../services/service-css/style.css'
import { Link } from 'react-router-dom'
const WebandMobileService = () => {
  return (
    
    <>
         <NavComponent></NavComponent>
		 <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>WEB AND APP SERVICES</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='SingleService'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 

		Premium App Development Services </h2>
		<p> Canvendor offers a complete end to end solutions in app design, incorporation, implementation, and management services. We have an all-encompassing experience in building all types of mobile applications like- eCommerce apps, consumer focused apps, or even a transformative enterprise class solutions. </p>
                 
{/* <p>Canvendor simplifies and modernizes the existing landscape and helps our customers stay ahead in the digital economy.The enterprise application of any organization is at the core of its IT structure. Canvendor helps digitally transform multiple processes in an organization including CX, CRM, HCM, SCMM, and ERP </p> */}
    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch'>
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>
               </div>
                </div>
              </div>


<div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'>CANVENDOR'S OFFERING</h1>
                      
                        </div>
        </div>

<div className='row'>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
			Native Mobile App Development
			</h3>
			<p>
			Developers at Canvendor can develop both Android and iOS native apps to meet the business requirements of our clients
			</p>
			
		</div>
	</div>
		<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Hybrid Mobile App Development </a>
			</h3>
			<p>
			Hybrid Mobile App Development helps build cross platform apps that work best in all types of settings owing to the unique amalgamation of technologies.
			</p>
			
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Progressive Web App Development </a>
			</h3>
			<p>
            With these apps Canvendor aims at developing native like competencies and easy to install apps which are easily accessible to all on any device with a singular codebase. 
			</p>
		
		</div>
	</div>
	
</div>
</div>
</div>

<div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Expertise across – iOS, Android, Cross platform, and Progressive App Developments </span>
        <h2 className='tp-section-title tp-section-title-large'> 

        Progressive App Developments </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div>

    </>
  )
}

export default WebandMobileService