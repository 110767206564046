import React from 'react'
import { Carousel } from 'react-bootstrap'

import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Typewriter from 'typewriter-effect';
import NavComponent from '../NavComponent';

const Banner = () => {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);
  //example showing all options
// let ps = new ParticleScript('#ps', {
//   count: 300,
//   colors: ['rgba(255,0,0,0.75)', 'rgba(0,255,0,0.75)', 'rgba(0,0,255,0.75)', 'rgba(0,255,255,0.75)', 'rgba(255,0,255,0.75)', 'rgba(255,255,0,0.75)'],
//   mouseMovementXSpeed: 0.5,
//   minSpeed: 0.1,
//   maxSpeed: 0.25,
//   //this stuff is only used if we are basing speed on size of particles
//   speedBasedOnSize: true,
//   sizeSpeedMultiplier: 0.25,
//   //this stuff is only used if we AREN'T basing speed on size of particles
//   minSize: 1,
//   maxSize: 10     
// })

//GUI Stuff
// let gui = new dat.GUI()
// let general = gui.addFolder('General')

// general.add(ps, 'count', 1, 1000).step(1).onChange(() => { ps.reset() })
// general.add(ps, 'colorText').onChange((value) => {
//   ps.colors = value.split(' ')
// })
// general.open()

// var size = gui.addFolder('Size')
// size.add(ps, 'minSize', 1, 100)
// size.add(ps, 'maxSize', 1, 100)
// size.open()

// var speed = gui.addFolder('Speed')
// speed.add(ps, 'speedBasedOnSize').onChange(() => {
//   ps.reset()
// })
// speed.add(ps, 'sizeSpeedMultiplier', -2, 2).step(0.01)
// speed.add(ps, 'minSpeed', -20, 20)
// speed.add(ps, 'maxSpeed', -20, 20)
// speed.add(ps, 'mouseMovementXSpeed', 0, 1).step(0.01)
// speed.open()

// gui.add(ps, 'reset')
  return (
   <>
   <NavComponent></NavComponent>
        <div id="homecon" className="homepart d-flex align-items-center pt-4" style={{ position: 'relative', height: '100vh', overflow:"hidden", zIndex:"-1"}}>
       
       
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            canvasClassName='homepart'
            options={{
                
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "grab",
                        },
                        resize: true,
                    },
                   /*  modes: {
                        push: {
                            quantity: 4,
                        },
                       repulse: {
                            distance: 200,
                            duration: 0.10,
                        },
                    },*/
                },
                particles: {
                    color: {
                        value: "rgba(120,233,253,0.2)", //"#ff5880",//rgba(120,233,253,0.2)
                    },
                    links: {
                        color: "rgba(120,233,253,0.2)", //"#ff5880",
                        distance: 150,
                        enable: true,
                        opacity: 0.2,
                        width: 0.4,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "right",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.4,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                   /* shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 3},
                    },*/
                },
                detectRetina: true,
            }}
        />
        
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
		<div data-aos="fade-right" className='mx-auto'
     data-aos-offset="300"
     data-aos-easing="ease-in-sine"><h1 className="maintitle" >Empowering Organizations With<br></br><span class="hover-underline-animation" style={{marginTop:'30px'}}>Digital <span class="hover-underline-animation" >Transformation</span></span></h1>
  
    </div>
    </div>
    </div>
    <svg class="arrows">
							<path class="a1" d="M0 0 L30 32 L60 0"></path>
							<path class="a2" d="M0 20 L30 52 L60 20"></path>
							<path class="a3" d="M0 40 L30 72 L60 40"></path>
						</svg>
                        </div>
</div>
</>
  )
}

export default Banner