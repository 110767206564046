import React from 'react'
import NavComponent from '../../components/NavComponent'
import '../services/service-css/style.css'
import { Link } from 'react-router-dom'
const ItSolution = () => {
  return (
    
    <>
       <NavComponent></NavComponent>
	   <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>IT SERVICES</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='SingleService'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 

		IT Consulting & Support </h2>
		<p>Canvendor is home to the best IT minds in the industry and their in-depth tech knowledge enables us as a team to create an all-encompassing strategy for digital and technological transformation in tandem with your business objectives.</p>
                    

    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch'>
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>
               </div>
                </div>
              </div>


<div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'> 

Our IT Consulting Process </h1>
                      
                        </div>
        </div>

<div className='row'>
<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Analysis  </a>
			</h3>
			<p>
			 Our software strategists study your current software infrastructure and the way it is been applied and used across various systems in the organization. They identify the roadblocks in the workflow and the automation
			</p>
			
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
			Strategy 
			</h3>
			<p>
			The next step is for our strategist to craft a roadmap to leverage the existing strengths of your business and scope of adding the latest IT infrastructure seamlessly partnering with the organization's employees on the way. 

 
			</p>
			
		</div>
	</div>

	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
			Performance  
			</h3>
			<p>
			The hallmark of a successful IT transformation is a successful collaboration between the client and the company. For Canvendor, client relationship is what a successful business liaison. 

 
			</p>
			
		</div>
	</div>
	
</div>
</div>
</div>


<div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 
 

		Software Consulting  </h2>
<p> The software consulting team is experienced and well-versed in tricks of the trade. The team conducts an in-depth analysis of how effective is your current software and also looks at your enterprise mobile strategy. After assessing the current scenario, the team comes up with possible suggestions for the changes, development needed, and a plan to remove the roadblocks.  </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div>
    </>
  )
}

export default ItSolution