import logo from './logo.svg';
import './App.css';

// import './static/vendor/aos/aos.css'
// import './static/vendor/bootstrap/css/bootstrap.min.css'
// import './static/vendor/boxicons/css/boxicons.min.css'
// import './static/vendor/glightbox/css/glightbox.min.css'
// import './static/vendor/remixicon/remixicon.css'
// import './static/vendor/swiper/swiper-bundle.min.css'
// import './static/css/style.css'
import NavComponent from './components/NavComponent';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import AimlService from './pages/services/AimlService';
import CloudService from './pages/services/CloudService';

import Contact from './pages/Contact';
import Semiconductors from './pages/industries/Semiconductors';
// import Seminew from './pages/industries/Seminew';
import Manufacturing from './pages/industries/Manufacturing';
import Healthcare from './pages/industries/Healthcare';
import CommunicationsAndInformation from './pages/industries/CommunicationsAndInformation';
import CreativeAgencies from './pages/industries/CreativeAgencies';
import Automotive from './pages/industries/Automotive';
import BankingAndFinance from './pages/industries/BankingAndFinance';
import Industries from './pages/Industries';

import Footer from './components/Footer';
import History from './pages/about/History';
// import MissionAndVision from './pages/about/MissionAndVision';
import WhyCanvendor from './pages/about/WhyCanvendor';

import ScrollToTop from './components/ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";

import EnquriyForm from './pages/EnquriyForm';
import DigitalService from './pages/services/DigitalService';
import ItSolution from './pages/services/ItSolution';
import Enterprise from './pages/services/Enterprise';
import WebandMobileService from './pages/services/WebandMobileService';
import InsightPage from './pages/InsightPage';
import Termsofservice from './pages/Termsofservice';
import Policy from './pages/Policy';
import CareerForm from './pages/carrer/CareerForm';
import Career from './pages/carrer/Career';
import Insurance from './pages/services/Insurance';




function App() {
  AOS.init({duration:900});
  
  return (<>
  <BrowserRouter>
  <ScrollToTop/>
  {/* <NavComponent/> */}
  <Routes>
    <Route path='/' exact element={<Home/>}/>
    <Route path='/about' element={<About/>}/>
    {/* about */}
    <Route path='/about/history' element={<History/>}/>
    {/* <Route path='/about/mission&vision' element={<MissionAndVision/>} /> */}
    <Route path='/about/whyCanvendor' element={<WhyCanvendor/>} />
    <Route path='/about/career' element={<Career/>} />

    <Route path='/services' element = {<Services/>}/>
    {/* services */}
    <Route path='/services/aiml' element={<AimlService/>}/>
    <Route path='/services/cloudSolutions' element={<CloudService/>}/>
    <Route path='/services/digitalTransformation' element={<DigitalService/>}/>
    <Route path='/services/itConsultingService' element={<ItSolution/>} />
    <Route path='/services/enterpriseService' element={<Enterprise/>}/>
    <Route path='/services/webAppService' element={<WebandMobileService/>} />
   

    <Route path='/industries' element={<Industries/>}/>

    <Route path='/industries/manufacturing' element={<Manufacturing/>} />
    <Route path='/industries/automotive' element={<Automotive/>} />
    <Route path='/industries/banking&finance' element={<BankingAndFinance/>} />
  
    <Route path='/industries/communications&information' element={<CommunicationsAndInformation/>} />
    <Route path='/industries/healthcare' element={<Healthcare/>}/>
    <Route path='/industries/insurance' element={<Insurance/>} />
    <Route path='/insights' element={<InsightPage/>}/>
    <Route path='/Terms' element={<Termsofservice/>}/>
    <Route path='/privacyPolicy' element={<Policy/>}/>
    {/* <Route path='/resources' element={<Resources/>}/> */}
    {/* resources */}
    {/* <Route path='/resources/blogs' element={<Blogs/>}/> */}
    {/* <Route path='/resources/caseStudies' element={<CaseStudies/>}/> */}
    {/* <Route path='/resources/industryInsights' element={<IndustryInsights/>}/> */}
    {/* <Route path='/resources/faq' element={<Faq/>}/> */}

    <Route path='/contactUs' element={<Contact/>} />
    <Route path='/form' element={<CareerForm/>} />
    <Route path='/carrer'  element={<Career/>}/>
    <Route path='/enquiry' element={<EnquriyForm/>} />
    <Route path='/contact/apac/tamilnadu' element={<Contact/>} />
  </Routes>
  <Footer/>
  </BrowserRouter>
  
  </>
    
  );
}

export default App;
