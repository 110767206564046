import React from 'react'
import NavComponent from '../../components/NavComponent'
import { Link } from 'react-router-dom'

const AimlService = () => {
  return (
    
    <>
    <NavComponent></NavComponent>
    <section className='page-bannernew pt-xs-60 pt-sm-80 overflow-hidden'>
          
</section>
<div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='heroPictureCardBox'>
                            <div className='heroPictureCardBoxTitle'>
                                <h1>ARTIFICIAL INTELLIGENCE</h1>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='SingleService'>
                <div className='container'>
               <div className='row'>
               <div className='col-lg-8'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Our misson is grow your Business</span>
        <h2 className='tp-section-title tp-section-title-large'> 

Artificial Intelligence and Machine Learning </h2>
<p> At Canvendor we understand the pain of collecting, processing, and analyzing data that is incredibly valuable, yet burdensome to handle. Here AI/ ML solutions come to the rescue by devising a data strategy that aids in taking logical and intelligent decisions.  </p>
    </div>
</div>
<div className='col-lg-4 d-flex justify-content-lg-end'><Link to='/contactUs'><a className='getintouch' >
    <div className='tp-theme-btn d-flex align-items-center white-bg'>
        <div className='tp-btn-inner'><p>Let’s Talk</p><b>Get in Touch</b>  <img src='../Vector1.png' className='pointerimg'></img></div>

    </div>
</a></Link></div>
               </div>
                </div>
              </div>


<div className='ContentBuilder-Item pt-140'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
        
                                <h1 className='tp-section-title tp-section-title-large pb-60'>CANVENDOR'S OFFERING</h1>
                      
                        </div>
        </div>

<div className='row'>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2  text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			
			</div>
			<h3 className='tp-service__title text-left pt-40 pb-25'>
				App Modernization
			</h3>
			<p>
			Moving legacy databases to modern databases is our strong suit. The task of shifting the database to the digital world can seem daunting at first, but in the long run, it will be a wise decision to take as it will eventually enhance user experience
			</p>
			
		</div>
	</div>
		<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title  text-left pt-40 pb-25'>
				<a>Strategic Decision Making </a>
			</h3>
			<p>
            A business leader when armed with the right tools can make choices that can change the course of the company in a positive direction. Cognizant business leaders take intelligent decisions. Having the backing of AI & ML algorithms 


			</p>
			
		</div>
	</div>
	<div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Company Driven by AI</a>
			</h3>
			<p>
            Leaders that are at the top of their game are using AI to gain insight into what is happening and how it is happening. This enables them to take proactive actions to stay ahead. AI enables you to look at all available resources. 
			</p>
		
		</div>
	</div>
	
</div>
</div>


</div>



                <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div>
              

    </>
  )
}

export default AimlService